<template>
  <div class="page">
    <span style="position: relative;">
      <el-input
        placeholder="请输入搜索关键字"
        v-model="search"
        prefix-icon="el-icon-search"
        clearable
        class="search"
        @input="inputSearch"
        @blur="keyupSearch($event)"
        @keyup.enter.native="keyupSearch($event)"
      >
      </el-input>
      <!-- <ul class="search_ul" v-if="inputData.length > 0">
        <li
          v-for="(item, index) in inputData"
          :key="index"
          @click="handleKeySearch(item)"
        >
          {{ item.value }}
        </li>
      </ul> -->
    </span>

    <el-tabs
      :stretch="true"
      v-model="activeName"
      @tab-click="handleClick"
      class="tabs"
    >
      <el-tab-pane label="热门专业" name="first">
        <div class="title">
          热门报考
        </div>
        <ul class="list" v-if="kaoHot.length > 0">
          <li
            v-for="(item, index) in kaoHot"
            :key="index"
            class="item"
            @click="handleDetail(item)"
          >
            <div class="cont">
              <img v-if="index == 0" src="../../assets/image/hot1.png" />
              <img v-else-if="index == 1" src="../../assets/image/hot2.png" />
              <img v-else-if="index == 2" src="../../assets/image/hot3.png" />
              <span v-else class="cont_num">{{ index + 1 }}</span>
              <div class="cont_name">
                {{ item.name }}
              </div>
            </div>
            <div class="hot">
              热度
              {{
                item.hits <= 10000
                  ? item.hits
                  : (item.hits / 10000).toFixed(1) + '万'
              }}
            </div>
          </li>
        </ul>
        <div v-else class="no_data">
          <img :src="noData" />
          <span class="text">暂无数据</span>
        </div>
        <div class="title">
          就业前景排名
        </div>
        <ul class="list" v-if="jobHot.length > 0">
          <li v-for="(item, index) in jobHot" :key="index" class="item">
            <div class="cont">
              <img v-if="index == 0" src="../../assets/image/hot1.png" />
              <img v-else-if="index == 1" src="../../assets/image/hot2.png" />
              <img v-else-if="index == 2" src="../../assets/image/hot3.png" />
              <span v-else class="cont_num">{{ index + 1 }}</span>
              <div class="cont_name">
                {{ item.name }}
              </div>
            </div>
            <div class="hot">
              热度
              {{
                item.hits <= 10000
                  ? item.hits
                  : (item.hits / 10000).toFixed(1) + '万'
              }}
            </div>
          </li>
        </ul>
        <div v-else class="no_data">
          <img :src="noData" />
          <span class="text">暂无数据</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全部专业" name="second">
        <div class="major-nav">
          <div class="nav-container">
            <template v-for="item in majorNavList">
              <div
                class="btn"
                :class="item.value == majorNav ? 'active' : ''"
                @click="handleMajorNav(item)"
              >
                {{ item.text }}
              </div>
            </template>
          </div>
        </div>
        <div class="list1" v-if="listData.length > 0">
          <van-sidebar v-model="activeKey" @change="handleJob">
            <van-sidebar-item
              v-for="(item, index) in listData"
              :key="index"
              :title="item.name"
              :class="item.class ? 'listActive' : ''"
            >
            </van-sidebar-item>
          </van-sidebar>
          <div class="right">
            <template v-for="item in listData[activeKey].major">
              <div class="right_title" @click="handleDetail(item.major[0])">
                <!-- {{ item.name }} -->
                <span v-html="showKeyWord(item.name)"> </span>
              </div>
              <ul class="right_list">
                <li
                  v-for="(ele, ind) in item.major"
                  :key="ind"
                  @click="handleDetail(ele)"
                >
                  <span v-html="showKeyWord(ele.name)"></span>
                </li>
              </ul>
            </template>
          </div>
        </div>
        <div v-else class="no_data">
          <img :src="noData" />
          <span class="text">暂无数据</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  data() {
    return {
      search: '',
      activeName: 'first',
      listData: [], //全部院校
      list: [], //全部院校
      noData: noData,
      kaoHot: [], //报考热度排名
      jobHot: [], //就业前景排名
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      majorNav: 'a',
      majorNavList: [
        { text: '本科专业', value: 'a' },
        { text: '专科专业', value: 'b' },
      ],
      inputData: [],

      activeKey: 0,
      showKey: false,
    };
  },
  mounted() {
    this.getMajorKaoHot();
    this.getMajorJobHot();
  },
  methods: {
    //search
    inputSearch() {
      if (!this.search) {
        this.showKey = false;
        this.listData = [];
        this.majorNav == 'a';
        this.getMajorList();
      }
    },
    keyupSearch(event) {
      event.target.blur();
      this.activeName = 'second';
      this.majorNav == 'a';
      this.getMajorList();
      this.showKey = true;
    },
    handleKeySearch(item) {
      this.search = item.value;
      this.inputData = [];
      // this.getCollegeList();
      this.$router.push({
        path: '/search/index',
        query: { type: 'major', search: item.value, pcId: '14' },
      });
    },
    // 关键字标红
    showKeyWord(val) {
      let str = val;
      if (this.showKey) {
        let words = this.search;

        if (str.search(words) != -1) {
          str = str.replace(
            words,
            '<span style="color:#910000; font-weight: bold;font-size:16px;">' +
              words +
              '</span>'
          );
        }
      }
      return str;
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeName == 'first') {
        this.getMajorKaoHot();
        this.getMajorJobHot();
      }
      if (this.activeName == 'second') {
        this.getMajorList();
      }
    },
    //点击选择专科还是本科
    handleMajorNav(item) {
      this.majorNav = item.value;
      let newList = [];
      if (this.majorNav == 'a') {
        newList = this.list.ben;
      } else {
        newList = this.list.zhuan;
      }
      let list = [];
      let arr = [];
      if (this.search) {
        for (let i = 0; i < newList.length; i++) {
          if (newList[i].name.search(this.search) != -1) {
            newList[i].class = true;
          } else {
            for (let j = 0; j < newList[i].major.length; j++) {
              if (newList[i].major[j].name.search(this.search) != -1) {
                newList[i].class = true;
              } else {
                for (let z = 0; z < newList[i].major[j].major.length; z++) {
                  if (
                    newList[i].major[j].major[z].name.search(this.search) != -1
                  ) {
                    newList[i].class = true;
                  }
                }
              }
            }
          }
        }
      }
      this.listData = newList;
    },
    //全部专业
    async getMajorList() {
      let apiUrl = '/php/major.list';
      try {
        const res = await this.$axios.get(apiUrl);
        console.log(res.data.data.ben, '==res', res);
        if (res.data.ret == 0) {
          this.list = res.data.data;
          let newList = [];
          if (this.majorNav == 'a') {
            newList = this.list.ben;
          } else {
            newList = this.list.zhuan;
          }
          let list = [];
          let arr = [];
          if (this.search) {
            for (let i = 0; i < newList.length; i++) {
              if (newList[i].name.search(this.search) != -1) {
                newList[i].class = true;
              } else {
                for (let j = 0; j < newList[i].major.length; j++) {
                  if (newList[i].major[j].name.search(this.search) != -1) {
                    newList[i].class = true;
                  } else {
                    for (let z = 0; z < newList[i].major[j].major.length; z++) {
                      if (
                        newList[i].major[j].major[z].name.search(this.search) !=
                        -1
                      ) {
                        newList[i].class = true;
                      }
                    }
                  }
                }
              }
            }
          }
          this.listData = newList;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //专业排名-报考热度排名
    async getMajorKaoHot() {
      let apiUrl = `/php/major.hot?type=kaoHot`;
      const data = {
        type: 'kaoHot',
      };
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.kaoHot = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //专业排名-就业前景排名
    async getMajorJobHot() {
      let apiUrl = `/php/major.hot?type=jobHot`;
      const data = {
        type: 'jobHot',
      };
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.jobHot = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },

    //专业详情
    handleDetail(item) {
      console.log('====专业详情', item);
      this.$router.push({
        path: '/majors/detail',
        query: {
          id: item.id,
          parentId: item.parentCode,
          type: item.eduLevel == 'ben' ? '本科' : '专科',
        },
      });
    },
    handleNodeClick(data) {
      console.log(data);
    },
    handleMajor(e) {
      console.log('====ee', e);
      if (e == 'a') {
        this.listData = this.list.ben;
      } else {
        this.listData = this.list.zhuan;
      }
    },
    handleJob(e) {},
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fff;
  padding-top: 50px;
}
.search {
  padding: 10px 12px;
  display: flex;
  align-items: center;

  /deep/ .el-input__inner {
    border: none !important;
    height: 40px !important;
    background-color: #f7f8fa;
    border-radius: 2px;
    color: #323233;
    font-size: 14px;
  }
  /deep/ .el-input__prefix {
    left: 15px;
    color: #323233;
  }
  /deep/ .el-input__suffix {
    right: 15px;
  }
}
.search_ul {
  position: absolute;
  background: #fff;
  z-index: 999;
  width: calc(100vw - 28px);
  left: 15px;
  border: 1px solid #ddd;
  border-top: none;
  /* top: 0; */
  margin-top: -10px;
  border-radius: 5px;
  padding: 10px 0;
  li {
    font-size: 14px;
    padding: 6px 20px;
  }
}
.van-dropdown-menu__item {
  justify-content: flex-end;
  padding-right: 20px;
}
.tabs {
  .el-tabs__header {
    border-bottom: 1px solid #f2f2f2;
    height: 44px;
    margin: 0;
    line-height: 44px;
  }
  .el-tabs__nav {
    // display: inline-flex;
    height: 44px;
    // justify-content: space-evenly;
    width: 100%;
  }
  .el-tabs__item {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    line-height: 42px;
    padding: 0 15px !important;
  }
  .el-tabs__item.is-active {
    color: #910000 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #910000;
    border-radius: 5px;
    width: 55px !important;
    margin-left: 52px;
  }
}
.title {
  font-size: 16px;
  color: #212121;
  font-weight: 700;
  padding: 20px 15px 0;
}
.major-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  .nav-container {
    width: 182px;
    height: 34px;
    background: #f5f5f5;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    position: relative;
    .btn {
      position: relative;
      z-index: 1;
      font-size: 14px;
      width: 88px;
      height: 30px;
      border-radius: 100px;
      line-height: 30px;
      text-align: center;
      color: #666;
    }
    .btn.active {
      color: #910000;
      background: #fff;
    }
  }
}
.van-sidebar-item--select {
  color: #222;
  font-weight: 800;
  border-color: #910000;
}
.van-sidebar-item.listActive {
  color: #910000;
  font-weight: bold;
}
.list {
  padding: 10px 15px;
  li.item {
    border-bottom: 1px solid #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  li.item {
    font-size: 14px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cont {
      color: #666;
      display: inline-flex;
      align-items: center;
      img {
        width: 20px;
      }
      .cont_num {
        color: #9b9b9b;
        font-size: 16px;
      }
      .cont_name {
        font-size: 16px;
        color: #212121;
        margin-left: 10px;
      }
      .hot {
        color: #666;
      }
    }
  }
}
.list1 {
  display: flex;
  .right {
    padding: 10px;
    width: 80%;
    height: 80vh;
    overflow: auto;
    .right_title {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333;
    }
    .right_list {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        background: #f9f9f9;
        margin: 0 8px 8px 0;
        padding: 8px 10px;
        border-radius: 3px;
      }
    }
  }
}
.del {
  padding: 10px 15px 0;
  text-align: right;
  font-size: 14px;

  .icons {
    font-size: 18px;
    margin-right: 1px;
    vertical-align: text-top;
  }
}
.comp_box {
  padding-bottom: 80px;
  .comp_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    padding: 10px 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    background: #fff;
    .el-button--primary {
      background: #910000;
      border-color: #910000;
      opacity: 0.5;
      padding: 10px 30px;
    }
  }
}
.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  img {
    width: 180px;
  }
  .text {
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%;
    font-weight: 600;
    padding-top: 20px;
  }
}
.el-message-box {
  width: 90%;
  .el-button {
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
  }
  .el-button--primary {
    background: #910000;
    border-color: #910000;
    color: #fff;
  }
}
</style>
